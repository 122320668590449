import {
  Layout,
  FolderClosed,
  External,
  LogoVercel,
  SettingsGear,
  Home,
  Terminal,
  CodeWrap,
  Api,
  ArrowGlobe,
  Isr,
  Middleware,
  Function,
  Globe,
  Image,
  Clock,
  Box,
  Logs,
  ClockRewind,
  FunctionRectangle,
  Analytics,
  Filter,
  AlignmentLeft,
  StopwatchFast,
  Check,
  Gauge,
  CheckCircle,
  CrossCircle,
  Compass,
  PlusCircle,
  Users,
  WindowGlobe,
  ListOrdered,
  Connection,
  ShieldCheck,
  ShieldGlobe,
  FileText,
  Shield,
  Key,
  LockClosed,
  Clipboard,
  RefreshClockwise,
  UserCheck,
  Kv,
  Postgres,
  Blob,
  CodeBracket,
  Archive,
  PencilEdit,
  Message,
  EyeDashed,
  BookClosed,
  Dollar,
  DollarFill,
  Sparkles,
  PictureInPicture,
  ToggleOnAlt,
  Cart,
} from 'geist/new-icons/16';
import type { JSX } from 'react';

export const NavIcons: Record<string, JSX.Element> = {
  '/docs/storage': <Home />,
  '/docs/observability': <Home />,
  '/docs/integrations': <Home />,
  '/docs/security/overview': <Home />,
  '/docs/getting-started-with-vercel': <LogoVercel />,
  '/docs/frameworks': <Layout />,
  '/docs/projects/overview': <FolderClosed />,
  '/docs/projects/project-configuration': <LogoVercel />,
  '/docs/deployments/overview': <External />,
  '/docs/deployments/builds': <SettingsGear />,
  '/docs/cli/': <Terminal />,
  '/docs/rest-api/': <CodeWrap />,
  '/docs/build-output-api/v3': <Api />,
  '/docs/edge-network/overview': <Globe />,
  '/docs/functions': <Function />,
  '/docs/functions/edge-middleware': <Middleware />,
  '/docs/image-optimization': <Image />,
  '/docs/incremental-static-regeneration': <Isr />,
  '/docs/edge-network/caching': <ArrowGlobe />,
  '/docs/infrastructure/data-cache': <Box />,
  '/docs/cron-jobs': <Clock />,
  '/docs/analytics': <Analytics />,
  '/docs/speed-insights': <StopwatchFast />,
  '/docs/observability/monitoring': <Filter />,
  '/docs/observability/runtime-logs': <ClockRewind />,
  '/docs/observability/activity-log': <AlignmentLeft />,
  '/docs/observability/log-drains': <Logs />,
  '/docs/observability/logs': <Logs />,
  '/docs/observability/otel-overview': <FunctionRectangle />,
  '/docs/observability/checks-overview': <Check />,
  '/docs/projects/domains': <WindowGlobe />,
  '/docs/accounts': <Users />,
  '/docs/pricing/spend-management': <DollarFill />,
  '/docs/dashboard-features/': <Layout />,
  '/docs/integrations/': <PlusCircle />,
  '/docs/limits/overview': <Gauge />,
  '/docs/errors': <CrossCircle />,
  '/docs/errors/error-list': <CrossCircle />,
  '/docs/production-checklist': <CheckCircle />,
  '/docs/incremental-migration': <ListOrdered />,
  '/docs/release-phases': <Compass />,
  '/docs/security': <FileText />,
  '/docs/security/shared-responsibility': <FileText />,
  '/docs/security/vercel-firewall': <Shield />,
  '/docs/security/ddos-mitigation': <ShieldGlobe />,
  '/docs/security/access-control': <UserCheck />,
  '/docs/security/saml': <Key />,
  '/docs/security/encryption': <LockClosed />,
  '/docs/security/directory-sync': <RefreshClockwise />,
  '/docs/security/secure-backend-access': <Connection />,
  '/docs/security/deployment-protection': <ShieldCheck />,
  '/docs/security/deployment-retention': <Clock />,
  '/docs/observability/audit-log': <Clipboard />,
  '/docs/storage/vercel-kv': <Kv />,
  '/docs/storage/vercel-postgres': <Postgres />,
  '/docs/storage/vercel-blob': <Blob />,
  '/docs/storage/edge-config': <CodeBracket />,
  '/docs/workflow-collaboration/vercel-toolbar': <LogoVercel />,
  '/docs/workflow-collaboration/comments': <Message />,
  '/docs/workflow-collaboration/feature-flags': <ToggleOnAlt />,
  '/docs/workflow-collaboration/visual-editing': <PencilEdit />,
  '/docs/workflow-collaboration/edit-mode': <PencilEdit />,
  '/docs/workflow-collaboration/draft-mode': <EyeDashed />,
  '/docs/workflow-collaboration/conformance': <Compass />,
  '/docs/workflow-collaboration/code-owners': <Users />,
  '/docs/workflow-collaboration/boundaries': <Box />,
  '/docs/vercel-platform/private-registry': <Archive />,
  '/docs/vercel-platform/glossary': <BookClosed />,
  '/docs/pricing': <Dollar />,
  '/docs/resources': <FolderClosed />,
  '/docs/integrations/install-an-integration': <PlusCircle />,
  '/docs/integrations/create-integration': <PictureInPicture />,
  '/docs/integrations/ai': <Sparkles />,
  '/docs/integrations/cms': <Api />,
  '/docs/integrations/ecommerce': <Cart />,
  '/docs/integrations/sign-in-with-vercel': <LogoVercel />,
};
