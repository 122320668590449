'use client';
import {
  createContext,
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  type JSX,
} from 'react';
import { usePathname } from 'next/navigation';
import { useTheme } from 'geist/core';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import { clsx } from 'clsx';
import { isMobile } from 'react-device-detect';
import { withAwaitPaint } from '@pyra/components/rendering/await-paint';
import { Badge, Text } from 'geist/components';
import { Link } from '@pyra/multi-zone/link';
import { tid } from '@vercel/geist-test-utils';
import { trimPath } from '@pyra/docs-shared/data/navigation/utils';
import { NavIcons } from '@pyra/docs-shared/data/navigation/icons';
import type { ExtraItem } from '@pyra/docs-shared/data/navigation/types';
import type { NavigationMenuItem } from './types';
import styles from './secondary-navigation.module.css';
import { ActionTrigger } from './action-trigger';

const HOVER_MENU_WIDTH = 480;
const HOVER_SMALL_BREAK = 400;
const HOVER_MID_SWITCH = 130;
const HOVER_MID_BREAK = 1200;
const HOVER_LG_BREAK = 1670;

interface NavigationMenuContextT {
  activeHref?: string;
}

const NavigationMenuContext = createContext<NavigationMenuContextT>(
  {} as NavigationMenuContextT,
);

interface NavigationMenuProps
  extends NavigationMenuPrimitive.NavigationMenuProps {
  data: NavigationMenuItem[];
  extraItems: ExtraItem[];
  activeHref?: string;
  className?: string;
}

// Add any paths here that we want to render as links instead of buttons
const navItemsAsLinks = ['/docs/all-products', '/docs/recipes'];

export function SecondaryNavigation({
  activeHref,
  className,
  data,
  extraItems,
}: NavigationMenuProps): JSX.Element {
  const path = usePathname() as string;
  const [value, setValue] = useState<string>('');
  const viewportRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement | HTMLAnchorElement | null>(null);
  const wrapperListRef = useRef<HTMLUListElement>(null);
  const [isHoveringViewport, setIsHoveringViewport] = useState(false);

  const checkMenuChildren = useCallback(
    (menuName: string): boolean => {
      const currentItem = data.filter((item) => item.name === menuName);
      if (currentItem.length > 0 && currentItem[0].posts === undefined) {
        return false;
      }
      return true;
    },
    [data],
  );

  useEffect(() => {
    //hide the hover when a page loads with a new path
    setValue('');
  }, [path]);

  useEffect(() => {
    const openedTriggerElement = document.querySelector('[data-state="open"]');
    if (!checkMenuChildren(value)) {
      setValue('');
    }
    const rootElement = document.documentElement;
    if (viewportRef.current && openedTriggerElement) {
      if (isMobile) {
        rootElement.style.overflow = 'hidden';
      }
      if (window.innerWidth <= HOVER_MENU_WIDTH) {
        viewportRef.current.style.setProperty('left', `24px`);
      } else if (
        window.innerWidth > HOVER_MENU_WIDTH &&
        window.innerWidth < HOVER_MID_BREAK
      ) {
        if (
          openedTriggerElement.getBoundingClientRect().x + HOVER_SMALL_BREAK >
          window.innerWidth
        ) {
          const widthTaken =
            openedTriggerElement.getBoundingClientRect().x - HOVER_MENU_WIDTH;
          if (widthTaken > 0) {
            viewportRef.current.style.setProperty(
              'left',
              `${
                openedTriggerElement.getBoundingClientRect().x -
                HOVER_SMALL_BREAK
              }px`,
            );
          } else {
            viewportRef.current.style.setProperty(
              'left',
              `${(window.innerWidth - HOVER_MENU_WIDTH) / 2}px`,
            );
          }
        } else {
          const widthTaken =
            openedTriggerElement.getBoundingClientRect().x + HOVER_MENU_WIDTH;
          if (widthTaken < window.innerWidth) {
            viewportRef.current.style.setProperty(
              'left',
              `${openedTriggerElement.getBoundingClientRect().x}px`,
            );
          } else {
            viewportRef.current.style.setProperty(
              'left',
              `${(window.innerWidth - HOVER_MENU_WIDTH) / 2}px`,
            );
            viewportRef.current.style.setProperty(
              'width',
              `${window.innerWidth}px`,
            );
          }
        }
      } else if (
        window.innerWidth >= HOVER_MID_BREAK &&
        window.innerWidth <= HOVER_LG_BREAK
      ) {
        const menuWidth = wrapperListRef.current?.getBoundingClientRect().width
          ? wrapperListRef.current.getBoundingClientRect().width
          : HOVER_MID_BREAK;
        if (
          openedTriggerElement.getBoundingClientRect().x <= HOVER_MID_SWITCH
        ) {
          viewportRef.current.style.setProperty(
            'left',
            `${openedTriggerElement.getBoundingClientRect().x}px`,
          );
        } else if (
          openedTriggerElement.getBoundingClientRect().x >
          menuWidth - 1.5 * HOVER_MID_SWITCH
        ) {
          viewportRef.current.style.setProperty(
            'left',
            `${
              openedTriggerElement.getBoundingClientRect().x -
              HOVER_MID_SWITCH * 2
            }px`,
          );
        } else {
          viewportRef.current.style.setProperty(
            'left',
            `${
              openedTriggerElement.getBoundingClientRect().x - HOVER_MID_SWITCH
            }px`,
          );
        }
      } else {
        //tackle large screen widths
        viewportRef.current.style.setProperty(
          'left',
          `${openedTriggerElement.getBoundingClientRect().x}px`,
        );
      }
    } else {
      rootElement.style.overflow = 'unset';
    }
  }, [checkMenuChildren, value]);

  const getNavItem = (dataItem: NavigationMenuItem, i: number): JSX.Element => {
    return (
      <NavigationMenuPrimitive.Item key={dataItem.name} value={dataItem.name}>
        <div data-testid={tid('header', 'secondary-nav', i.toString())}>
          <MenuTrigger
            currentItem={i}
            href={
              navItemsAsLinks.includes(dataItem.href) ? dataItem.href : null
            }
            showArrow={
              dataItem.posts?.length !== undefined && dataItem.posts.length > 0
            }
            totalItems={data.length}
          >
            {isMobile && dataItem.posts?.length ? (
              dataItem.name
            ) : (
              <Link href={dataItem.href}>{dataItem.name}</Link>
            )}
          </MenuTrigger>
        </div>
        <>
          {dataItem.posts?.length !== undefined &&
            dataItem.posts.length > 0 && (
              <NavigationMenuPrimitive.Content
                className={clsx(styles.content, styles.navigationMenuWindow, {
                  [styles.hidden]: value !== `${dataItem.name}`,
                })}
                forceMount
              >
                <ul className={clsx(styles.menuItemsGroup)}>
                  <li className={clsx(styles.menuItemsLinks)}>
                    {dataItem.posts
                      .filter((item) => !item.excludeFromNavHoverCard)
                      .map((filteredItem) => (
                        <NavigationMenuPrimitive.Link
                          asChild
                          key={filteredItem.name}
                        >
                          <MenuSubLink
                            badge={filteredItem.badge}
                            data-feature={filteredItem.name}
                            description={filteredItem.description}
                            href={filteredItem.href}
                            icon={NavIcons[filteredItem.href]}
                            path={path}
                            title={filteredItem.name}
                          />
                        </NavigationMenuPrimitive.Link>
                      ))}
                    {extraItems
                      .filter((extraItem) => extraItem.depth[1] === i)
                      .map((filteredItem) => (
                        <NavigationMenuPrimitive.Link
                          asChild
                          key={filteredItem.name}
                        >
                          <MenuSubLink
                            data-feature={filteredItem.name}
                            description={filteredItem.description}
                            href={filteredItem.href}
                            icon={NavIcons[filteredItem.href]}
                            path={path}
                            title={filteredItem.name}
                          />
                        </NavigationMenuPrimitive.Link>
                      ))}
                  </li>
                </ul>
              </NavigationMenuPrimitive.Content>
            )}
        </>
      </NavigationMenuPrimitive.Item>
    );
  };

  return (
    <NavigationMenuPrimitive.Root
      aria-label="Secondary navigation"
      className={clsx(styles.root, className)}
      delayDuration={0}
      onValueChange={withAwaitPaint((v: string): void => {
        if (v) {
          triggerRef.current = document.activeElement as
            | HTMLButtonElement
            | HTMLAnchorElement;
        } else if (triggerRef.current) {
          triggerRef.current.focus();
        }
        if (!v && isHoveringViewport) {
          return;
        }
        setValue(v);
      })}
      value={value}
    >
      <NavigationMenuContext.Provider
        value={useMemo(() => ({ activeHref }), [activeHref])}
      >
        <NavigationMenuPrimitive.List
          className={styles.list}
          ref={wrapperListRef}
        >
          {data.map((dataItem, i) => getNavItem(dataItem, i))}
          <NavigationMenuPrimitive.Indicator
            className={clsx(
              styles.indicator,
              !checkMenuChildren(value) && styles.hideIndicator,
            )}
          >
            <svg
              className={styles.indicatorMarker}
              fill="none"
              viewBox="0 0 44 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.138 2.718a6 6 0 0 0-8.134.064L2.5 16.5H42L26.138 2.718Z"
                fill="#D9D9D9"
              />
              <path
                d="M1 15h3.142L16.485 3.657a8 8 0 0 1 11.314 0L40.142 15H43"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </NavigationMenuPrimitive.Indicator>
        </NavigationMenuPrimitive.List>
      </NavigationMenuContext.Provider>
      <div
        className={styles.NavigationMenuViewportWrapper}
        onMouseEnter={(): void => {
          setIsHoveringViewport(true);
        }}
        onMouseLeave={(): void => {
          setIsHoveringViewport(false);
        }}
        ref={viewportRef}
      >
        <NavigationMenuPrimitive.Viewport
          className={styles.NavigationMenuViewport}
          forceMount={isMobile ? undefined : true}
        />
      </div>
    </NavigationMenuPrimitive.Root>
  );
}

function MenuTrigger({
  children,
  ...props
}: NavigationMenuPrimitive.NavigationMenuTriggerProps & {
  children:
    | string
    | {
        props: { children: string };
      };
  href: string | null;
  showArrow: boolean;
  currentItem: number;
  totalItems: number;
}): JSX.Element {
  const { href, showArrow, currentItem, totalItems } = props;

  const label: string =
    typeof children === 'string'
      ? (children as string)
      : (children.props as { children: string }).children;

  return (
    <NavigationMenuPrimitive.Trigger
      asChild
      className={clsx(
        styles.trigger,
        currentItem === 0 && styles.firstItem,
        currentItem === totalItems - 1 && styles.lastItem,
      )}
    >
      {ActionTrigger({ href, label, showArrow })}
    </NavigationMenuPrimitive.Trigger>
  );
}
export const MenuSubLink = forwardRef(function MenuSubLink(
  {
    className,
    description,
    title,
    icon,
    href,
    path,
    key,
    badge,
    onClick,
  }: {
    description: string | undefined;
    title: string;
    className?: string;
    icon?: React.ReactNode;
    href: string;
    path?: string;
    key?: string;
    badge?: string;
    onClick?: () => void;
  },
  ref: React.Ref<HTMLAnchorElement>,
): JSX.Element {
  const { resolvedTheme: theme } = useTheme();
  const isActive = trimPath(path) === trimPath(href);
  const clsxOpts = {
    [styles.menuDown]: isActive,
  };
  if (!isActive && className) {
    clsxOpts[className] = true;
  }

  return (
    <Link
      className={clsx(styles.menuSubLink, clsxOpts)}
      href={href}
      key={key ? key : title}
      onClick={onClick}
      ref={ref}
      tabIndex={0}
    >
      <div
        className={clsx(styles.menuItemHeading, {
          [styles.menuTextDown]: isActive,
        })}
      >
        {icon ? (
          <span className={clsx({ [styles.darkIcon]: theme === 'dark' })}>
            {icon}
          </span>
        ) : null}
        <span>{title}</span>
        {badge ? (
          <Badge className="" size="sm" variant="blue-subtle">
            {badge}
          </Badge>
        ) : null}
      </div>
      <Text as="p" className={styles.menuItemText} lineHeight={20} size={14}>
        {description}
      </Text>
    </Link>
  );
});
