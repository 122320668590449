import { ChevronDown as IconChevronDown } from 'geist/icons';
import type { JSX } from 'react';
import { Link } from '@pyra/multi-zone/link';
import styles from './secondary-navigation.module.css';

interface ActionTriggerProps {
  href: string | null;
  label: string;
  showArrow: boolean;
}

export function ActionTrigger({
  href,
  label,
  showArrow,
}: ActionTriggerProps): JSX.Element {
  const Component = href ? Link : 'button';
  return (
    // Fix the weird error: "An empty string ("") was passed to the href attribute. To fix this, either do not render the element at all or pass null to href instead of an empty string"
    <Component className="no-underline" href={href === null ? undefined : href}>
      <span className={styles.bottomHighlight}>
        {label}
        {showArrow ? <IconChevronDown aria-hidden="true" /> : null}
      </span>
    </Component>
  );
}
